import React, { Component } from "react";
import EventsWithImage from "./EventsWithImage";

interface Props {
  title: string;
  description: string;
}

export default class MissionWithContainerStyle extends Component<Props> {
  render() {
    return (
      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {this.props.title}
          </p>
          <p className="mt-5 max-w-prose mx-auto text-lg text-gray-500">
            {this.props.description}
          </p>
          <div className="mt-12">
            <EventsWithImage limit={3} contentOnly={true} />
          </div>
        </div>
      </div>
    );
  }
}
