import React, { Component } from "react";
import LeftContentCard from "../components/base/LeftContentCard";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import MissionWithContainerStyle from "../components/MissionWithContainerStyle";
import SEO from "../components/Seo";
import SupportedLogos from "../components/SupportedLogos";
import AboutHero from "../images/about_hero.webp";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - About Us"
          description="DMAP (Digital Makers Asia Pacific is a team of Software Engineers and Developers that got together by participating in one or more of the APEC App Challenge events. This community aims to support any of your tech-related endeavour, provide a forum for you to mentor one another, develop leadership skills, thereby multiplying your impact."
        />
        <Hero
          title=""
          titleBlue="About Us"
          description="DMAP's members come from a wide variety of backgrounds and cultures from different places in the Asia Pacific region. Our community is made up of young software developers and digital entrepreneurs from different APEC economies. We support each other through mission-driven initiatives, and we provide a forum for you to become a mentor and a mentee all while developing important leadership skills that expand the impact of our members."
          image={AboutHero}
        />
        <LeftContentCard />
        <MissionWithContainerStyle
          title="DMAP Members Expansion"
          description="We normally invite shining stars that have previously participated in APEC App Challenges. Nevertheless, we are excited to onboard anyone who is passionate in delivering our vision statement"
        />
        <SupportedLogos
          description="Text goes here."
          svgIcon="https://tailwindui.com/img/logos/workcation-logo-indigo-900.svg"
        />
        <Footer />
      </div>
    );
  }
}
